@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&family=Reem+Kufi:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Nunito", sans-serif;
}

body.busy-cursor {
  cursor: wait !important;
}
body.busy-cursor div {
  pointer-events: none;
}

.l {
  box-shadow: 0 0 0 1px red;
}

* {
  scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(219, 213, 213, 0.7882352941);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.ff-reemkufi {
  font-family: "Reem Kufi", sans-serif;
}

header {
  transition: 500ms;
  width: calc(100% - 280px);
  margin-left: 280px;
  position: fixed !important;
  top: 0;
  right: 0;
  align-items: center;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  z-index: 20;
  padding: 15px 22px;
}

aside {
  transition: 500ms;
  width: 280px;
  height: 100vh;
  background: #fafbfc;
  border-right: 1px solid #e9ebf0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 29;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
}
aside.scroll-disabled {
  pointer-events: none;
}
aside .dashboard-logo {
  transition: 500ms;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 37px 40px 37px;
}
aside .dashboard-logo a {
  text-decoration: none;
}
aside .dashboard-logo a img {
  min-width: 80px;
}
aside .dashboard-logo button {
  transition: 500ms;
  background: transparent;
  cursor: pointer;
  color: #8b8b8b;
  padding: 0;
  border: none;
  outline: none;
  line-height: 1;
  font-size: 30px;
}
aside .dashboard-logo button:hover {
  color: #1a71a7;
}
aside strong {
  padding-left: 20px;
  font-weight: 600;
  font-size: 18px;
}
aside ul {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
aside ul .nav-link {
  text-decoration: none;
  height: 44px;
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
aside ul .nav-link:hover {
  background: #e9f2ff;
  color: #097ba9;
}
aside ul .nav-link:hover sup {
  color: #9d3784;
}
aside ul .nav-link.nav-toggle::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  right: 15px;
  top: calc(50% - 6px);
  transform: rotate(-135deg);
  transition: 300ms;
}
aside ul .nav-link.nav-toggle:hover::before {
  border-left-color: #097ba9;
  border-bottom-color: #097ba9;
}
aside ul .nav-link.nav-toggle.active {
  color: #097ba9;
  background-color: #e9f2ff;
}
aside ul .nav-link.nav-toggle.active::before {
  top: calc(50% - 8px);
  transform: rotate(-45deg);
  border-left: 2px solid #097ba9;
  border-bottom: 2px solid #097ba9;
}
aside ul .nav-link strong {
  padding-left: 12px;
  pointer-events: none;
}
aside ul .navmore-link {
  position: relative;
  margin: 0 auto;
  border-radius: 5px;
  display: none;
}
aside ul .navmore-link.active {
  display: block;
}
aside ul .navmore-link a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 10px 14px;
  font-weight: 600;
  font-size: 16px;
  color: #2a2a2a;
}
aside ul .navmore-link a:hover {
  color: #097ba9;
}

.wrapper-setting {
  transition: 500ms;
  min-height: 100vh;
  margin-left: 310px;
  padding-top: 25px;
  padding-right: 30px;
  position: relative;
  z-index: 18;
}

.wrapper {
  transition: 500ms;
  min-height: 100vh;
  margin-left: 295px;
  padding-top: 97px;
  padding-right: 15px;
  position: relative;
  z-index: 18;
}

.shrink-menu aside {
  transition: 500ms;
  width: 110px;
}
.shrink-menu aside .dashboard-logo {
  transition: 500ms;
  flex-direction: column;
}
.shrink-menu aside .dashboard-logo button {
  transition: 500ms;
  margin-top: 10px;
}
.shrink-menu aside .invite-box {
  display: flex;
  justify-content: center;
}
.shrink-menu aside .invite-box span {
  display: none;
}
.shrink-menu aside .aside-modelbtn {
  display: flex;
  justify-content: center;
}
.shrink-menu aside .aside-modelbtn span,
.shrink-menu aside .aside-modelbtn .icon {
  display: none;
}
.shrink-menu aside ul {
  word-break: break-all;
}
.shrink-menu aside ul .nav-link {
  display: flex;
  justify-content: center;
  padding-left: 10px;
}
.shrink-menu aside ul .nav-link strong {
  display: none;
}
.shrink-menu aside .navmore-link {
  padding: 0px;
}
.shrink-menu aside .navmore-link a {
  padding: 10px 5px;
  font-size: 13px;
}
.shrink-menu aside .nav-toggle::before {
  content: "";
  display: none;
}
.shrink-menu header {
  transition: 500ms;
  width: calc(100% - 110px);
  margin-left: 147px;
  padding-left: 37px;
}
.shrink-menu .wrapper {
  transition: 500ms;
  margin-left: 147px;
}
.shrink-menu *::-webkit-scrollbar {
  width: 10px;
}
.shrink-menu *::-webkit-scrollbar-thumb {
  border: 2px solid #ffffff;
}

.text-btn li {
  padding: 15px 0;
}
.text-btn li:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}

.dropdown:hover .dropdown-menu {
  display: block;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.dropdown-aside {
  display: none;
  width: 180px;
  height: auto;
  z-index: 50;
  position: fixed;
}

.tooltip {
  background: linear-gradient(90deg, rgb(9, 123, 169) 0%, rgb(155, 55, 133) 100%);
}
.tooltip .rotate-45 {
  background: #097ba9;
}

.table {
  width: 100%;
  color: #949494;
  border-collapse: collapse;
}
.table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}
.table thead th {
  background: #fafbfc;
}
.table thead th:first-child {
  border-radius: 25px 0 0 25px;
}
.table thead th:last-child {
  border-radius: 0 25px 25px 0;
}
.table thead,
.table tbody {
  box-shadow: 0 0 0 1px #dcdcdc;
  border-radius: 25px;
}
.table tbody.tbody-gap {
  opacity: 0;
  line-height: 5px;
}
.table tbody.tbody-gap td {
  opacity: 0;
  line-height: 5px;
  padding-bottom: 15px;
  border: 0;
}
.table tbody tr:first-child td:first-child {
  border-radius: 25px 0 0 0px;
}
.table tbody tr:first-child td:last-child {
  border-radius: 0 25px 0 0;
}
.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 25px;
}
.table tbody tr:last-child td:last-child {
  border-radius: 0 0 25px 0;
}
.table tbody tr td {
  background: #fafbfc;
  border-bottom: 1px solid #dcdcdc;
}
.table tbody tr:last-child td {
  border-bottom: none;
}
.table tbody .group {
  border-radius: 25px !important;
  padding: 0 !important;
  background: #236da5 !important;
}
.table tr {
  color: #949494;
}
.table tr th {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 0px;
  width: auto;
  min-width: 140px;
}
.table tr td {
  text-align: center;
  padding: 12px 0px;
}

.modal-dropdown .group {
  border-radius: 25px !important;
  padding: 0 !important;
  border: none !important;
  background: linear-gradient(90deg, rgb(9, 123, 169) 0%, rgb(155, 55, 133) 100%) !important;
}
.modal-dropdown .group svg {
  display: none !important;
}

.tabs-box .space-x-2 button {
  border-radius: 25px !important;
  padding: 8px 12px;
  border: 1px solid #0891b2;
  margin-right: 20px;
}

.modal-aside {
  position: relative;
}
.modal-aside .aside {
  width: 240px;
  border-right: 1px solid #e0e0e0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
}
.modal-aside .aside ul {
  list-style-type: none;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 10px;
}
.modal-aside .aside ul .nav-link {
  text-decoration: none;
  height: 38px;
  padding-left: 15px;
  padding-right: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.modal-aside .aside ul .nav-link:hover {
  background: #e9f2ff;
  color: #097ba9;
}
.modal-aside .aside ul .nav-link:hover sup {
  color: #9d3784;
}
.modal-aside .aside ul .nav-link.nav-toggle::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  right: 15px;
  top: calc(50% - 6px);
  transform: rotate(-135deg);
  transition: 300ms;
}
.modal-aside .aside ul .nav-link.nav-toggle:hover::before {
  border-left-color: #097ba9;
  border-bottom-color: #097ba9;
}
.modal-aside .aside ul .nav-link.nav-toggle.active {
  color: #097ba9;
  background-color: #e9f2ff;
}
.modal-aside .aside ul .nav-link.nav-toggle.active::before {
  top: calc(50% - 8px);
  transform: rotate(-45deg);
  border-left: 2px solid #097ba9;
  border-bottom: 2px solid #097ba9;
}
.modal-aside .aside ul .nav-link strong {
  padding-left: 12px;
  pointer-events: none;
  font-weight: 500;
  font-size: 18px;
}
.modal-aside .aside ul .navmore-link {
  position: relative;
  margin: 0 auto;
  border-radius: 5px;
  display: none;
}
.modal-aside .aside ul .navmore-link.active {
  display: block;
}
.modal-aside .aside ul .navmore-link a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 10px 14px;
  font-weight: 500;
  font-size: 18px;
  color: #2a2a2a;
}
.modal-aside .aside ul .navmore-link a:hover {
  color: #097ba9;
}

.ck.ck-powered-by {
  display: none !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #333;
  /* You can set the color you prefer */
  border-radius: 50%;
  margin: 0 5px;
  animation: pulse 1.2s infinite;
}

.dot1 {
  animation-delay: 0.2s;
  background-color: #2ecc71;
}

.dot2 {
  animation-delay: 0.4s;
  background-color: #27ae60;
}

.dot3 {
  animation-delay: 0.6s;
  background-color: #229954;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}
.tox-statusbar__branding {
  display: none;
}

.i-tooltip {
  width: 267px;
  height: 208px;
  top: 632px;
  left: 672px;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2509803922);
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.code-view {
  padding: 1rem;
  margin: 10px;
}

.text-area {
  width: 100%;
  background: black;
  border: 1px solid #ccc;
  color: white;
  min-height: 200px;
}

.modal-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000 !important;
  background: rgba(0, 0, 0, 0.25);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  transition: 350ms;
}
.modal-box.show {
  visibility: visible;
  opacity: 1;
}
.modal-box .popup-body {
  width: 100%;
  max-width: 1000px;
  height: auto;
  background: #ffffff;
  box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000 !important;
  padding: 20px 30px;
  background: white;
}
.modal-box .popup-body .pop-btn {
  outline: none;
  border: none;
  color: #949494;
  background: transparent;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.chat-field textarea {
  padding: 10px 40px 10px 10px;
  border: 1px solid white;
  outline: none;
  resize: none;
  transition: 0.25s;
}
.chat-field textarea:focus {
  border: 1px solid rgb(56, 140, 197);
  box-shadow: 0 0 0 0.2rem rgba(59, 133, 182, 0.25);
}

.chat-field .sendbtn {
  background-image: linear-gradient(43deg, #1e6fa6 0%, #9d3784 46%, #9d3784 100%);
}

.chat-field .sendbtn:hover {
  color: #ffffff;
  transform: scale(1.35);
  outline: none;
}

.title {
  align-items: center;
  white-space: nowrap;
}

.title--center-line {
  text-align: center;
}
.title--center-line::before {
  content: "";
  background: #f1f1f1;
  height: 1px;
  width: 100%;
  margin-right: 1rem;
}
.title--center-line::after {
  content: "";
  background: #f1f1f1;
  height: 1px;
  width: 100%;
  margin-left: 1rem;
}

.uploader {
  display: block;
  width: 80px;
  height: 80px;
}
.uploader label {
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  background: #f2f7ff;
  border-radius: 50%;
  transition: all 0.2s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 80px;
  height: 80px;
}
.uploader #start {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #start.hidden {
  display: none;
}
.uploader #start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}
.uploader #response {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #response.hidden {
  display: none;
}
.uploader #response #messages {
  margin-bottom: 0.5rem;
}
.uploader #file-image {
  display: inline;
  width: auto;
  height: auto;
  max-width: 80px;
  width: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.uploader #file-image.hidden {
  display: none;
}
.uploader #notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}
.uploader #notimage.hidden {
  display: none;
}
.uploader input[type=file] {
  display: none;
}

.avtart-shadow {
  box-shadow: 0px 0px 8px 0px rgba(99, 99, 99, 0.1411764706);
}

.avtar-boxes div {
  width: 44px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
}
.avtar-boxes div img {
  border-radius: 50%;
}
.avtar-boxes div:hover {
  border: 1px solid #5de56a;
}

.workspace-main {
  height: 100vh;
  overflow-y: hidden;
}
.workspace-main .workspace-main__space {
  height: 180px;
}
.workspace-main .workspace-main__space_last {
  height: 180px;
}

button:disabled {
  cursor: not-allowed;
}

/* styles.css */
.blinking-cursor {
  right: 5px;
  top: 5px;
  height: 1em;
  width: 0.5em;
  background-color: rgb(56, 140, 197);
  animation: blinkCursor 1s infinite;
}

@keyframes blinkCursor {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(219, 219, 219, 0.5), transparent);
  animation: loading 1.5s infinite;
}

.skeleton > * {
  visibility: hidden;
}

@keyframes loading {
  to {
    left: 100%;
  }
}
.text-editor a {
  color: #0d6efd;
  text-decoration: underline;
}
.text-editor a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
.text-editor h1 {
  font-size: 2.5rem;
}
.text-editor h2 {
  font-size: 2rem;
}
.text-editor h3 {
  font-size: 1.75rem;
}
.text-editor h4 {
  font-size: 1.5rem;
}
.text-editor h5 {
  font-size: 1.25rem;
}
.text-editor h6 {
  font-size: 1rem;
}
.text-editor h1,
.text-editor h2,
.text-editor h3,
.text-editor h4,
.text-editor h5,
.text-editor h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.text-editor ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.text-editor ul li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.text-editor ol {
  padding-left: 2rem;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.text-editor ol li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.text-editor p {
  padding: 0.8em 0;
}

.card-boxes .card {
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1490196078);
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.9s;
}
.card-boxes .card .lable-box {
  color: #cf4dcb;
  font-size: 10px;
  font-weight: 600;
  background: #ffe5fe;
  padding: 8px 20px;
  border-radius: 25px;
}
.card-boxes .card .card-body {
  padding: 20px 0px 10px 0px;
}
.card-boxes .card .card-body .check-card {
  font-size: 14px;
}
.card-boxes .card .card-body h5 {
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(90deg, #097ba9 0%, #9f3783 50%, #9f3783 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-boxes .card .card-body .ctn-btn {
  text-decoration: none;
  display: block;
  text-align: center;
}
.card-boxes .card .card-body ul {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
}
.card-boxes .card .card-body ul li {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}
.card-boxes .card .card-body ul li span {
  font-size: 24px;
  margin-right: 10px;
}
.card-boxes .card .card-body .rate-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-boxes .card .card-body .rate-box small {
  font-family: Arial;
  padding: 0 5px;
  font-size: 34px;
  line-height: 1;
  font-weight: 700;
  background: linear-gradient(90deg, #097ba9 0%, #9f3783 50%, #9f3783 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-boxes .card .card-body .rate-box strong {
  font-size: 50px;
  font-weight: 700;
  background: linear-gradient(90deg, #097ba9 0%, #9f3783 50%, #9f3783 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-boxes .card .card-body .rate-box sub {
  font-size: 14px;
  font-weight: 400;
}
.card-boxes .card .card-body .badge-box {
  display: inline;
  background: #efefef;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
}
.card-boxes .card .card-body .badge-box strong {
  font-weight: 800;
}
.card-boxes .card:hover {
  background: rgb(68, 97, 155);
  background: linear-gradient(0deg, rgb(149, 60, 134) 0%, rgb(68, 97, 155) 100%);
  color: #ffffff;
  transform: scale(1.06);
}
.card-boxes .card:hover .rate-box small {
  filter: brightness(0) invert(1);
}
.card-boxes .card:hover .check-card {
  filter: brightness(0) invert(1);
}
.card-boxes .card:hover h5 {
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: none !important;
  color: #ffffff !important;
}
.card-boxes .card:hover .btn-default {
  color: #704c8f;
}
.card-boxes .card:hover .lable-box {
  background: #ffffff;
}
.card-boxes .card .card-footer {
  margin-top: auto;
  padding: 20px;
}
.card-boxes .card-selected {
  background: rgb(68, 97, 155);
  background: linear-gradient(0deg, rgb(149, 60, 134) 0%, rgb(68, 97, 155) 100%);
  color: #ffffff;
  transform: scale(1.06);
}
.card-boxes .card-selected .rate-box small {
  filter: brightness(0) invert(1);
}
.card-boxes .card-selected .check-card {
  filter: brightness(0) invert(1);
}
.card-boxes .card-selected h5 {
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: none !important;
  color: #ffffff !important;
}
.card-boxes .card-selected .btn-default {
  color: #704c8f;
}
.card-boxes .card-selected .lable-box {
  background: #ffffff;
}

.btn-default-cancel {
  background: transparent;
  padding: 0.5rem 1rem;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid black;
  border-radius: 25px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.4;
}

.btn-default {
  background: transparent;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(90deg, white, white), linear-gradient(90deg, #097ba9, #9c3784);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid transparent;
  border-radius: 25px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.otp-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp-input input {
  width: 68%;
  height: 64px;
  margin: 5px 14px;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #0c0d25;
  text-align: center;
}
.otp-input input {
  background: #eac5df;
  border: none;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2509803922);
}
.otp-input input:invalid {
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2509803922);
}

.main-body {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.main-wrapper {
  height: 100vh;
  position: relative;
  background: url("../public/images/common-bg.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.main-wrapper .button-image {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  transition: 250ms;
  z-index: 100;
  width: 220px;
}
.main-wrapper .button-image img {
  max-width: 100%;
  display: block;
}
.main-wrapper .button-image:hover, .main-wrapper .button-image:active, .main-wrapper .button-image:focus {
  transform: scale(1.03);
}
.main-wrapper .cloud-box {
  position: absolute;
  overflow: hidden;
  width: 100%;
}
.main-wrapper .cloud-box #clouds {
  padding: 10px 0;
}
.main-wrapper .cloud-box .cloud {
  width: 200px;
  height: 80px;
}
.main-wrapper .cloud-box .cloud img {
  height: 100%;
}
.main-wrapper .cloud-box .x1 {
  animation: moveclouds 20s linear infinite;
}
.main-wrapper .cloud-box .x2 {
  left: 200px;
  transform: scale(0.6);
  animation: moveclouds 25s linear infinite;
}
.main-wrapper .cloud-box .x3 {
  left: -250px;
  top: -200px;
  transform: scale(0.8);
  animation: moveclouds 30s linear infinite;
}
@keyframes moveclouds {
  0% {
    margin-left: 100vw;
  }
  100% {
    margin-left: -100vw;
  }
}

/*Start | Progressbar style*/
.progressing-bar {
  left: 0;
  margin: 0px auto;
  position: relative;
  width: 100%;
  max-width: 820px;
  height: 88px;
  background-color: transparent;
  border-radius: 22px;
  border: 6px solid #ffffff;
  padding: 4px;
  text-align: center;
}
.progressing-bar .progressing-in {
  border: 6px solid #fff;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #097ba9, #9f3782);
  position: relative;
  transition: 300ms;
}
.progressing-bar .progressing-in::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-45deg, rgb(255, 255, 255) 25%, transparent 25%, transparent 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 75%, transparent 75%, transparent);
  background-size: 38px 38px;
  animation: animate-stripes 20s linear infinite;
}
.progressing-bar h2 {
  margin: 0;
  font-size: 24px;
  color: #fff;
}
.progressing-bar input[type=range] {
  width: 100%;
  max-width: 280px;
  margin-top: 50px;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
/*End | Progressbar style*/
.animation {
  display: inline-block;
  margin-bottom: 2%;
}

.test,
.one,
.two,
.three {
  display: block;
  float: left;
}

.one {
  background: url("../public/images/setting.svg");
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 40px;
  margin-right: 6px;
}

.test {
  background: url("../public/images/setting.svg");
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 65px;
  margin-right: -46px;
}

/* pap */
.two {
  background: url("../public/images/setting.svg");
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* pap */
.three {
  background: url("../public/images/setting.svg");
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 40px;
  margin-left: 6px;
}

/* pap */
.four {
  background: url("../public/images/setting.svg");
  width: 54px;
  height: 54px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 65px;
  margin-left: 130px;
}

.five {
  background: url("../public/images/setting.svg");
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: -20px;
  margin-left: 100px;
}

@keyframes spin-test {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
.spin-test {
  animation: spin-test 1.5s infinite linear;
}

@keyframes spin-one {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
.spin-one {
  animation: spin-one 1.5s infinite linear;
}

@keyframes spin-two {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.spin-two {
  animation: spin-two 2s infinite linear;
}

.content-box {
  position: relative;
  margin: 0px auto;
  width: 100%;
  height: auto;
  max-width: 1280px;
}
.content-box .modal-bg {
  background: url("../public/images/bg-modal.svg") no-repeat;
  width: 100%;
  background-size: 100% 100%;
  height: 92vh;
  position: relative;
}
.content-box .modal-permission-bg {
  background: url("../public/images/bg-modal.svg") no-repeat;
  width: 100%;
  max-width: 900px;
  background-size: 100% 100%;
  height: 42vh;
  position: relative;
  margin: 0px auto;
}
.content-box .content-inner {
  width: 80%;
  display: flex;
  gap: 0 4%;
  justify-content: center;
}
.content-box .content-inner .content-col {
  width: 98%;
}
.content-box .content-inner .imgcard-wrapper {
  padding-top: 10%;
  display: flex;
  flex-wrap: wrap;
}
.content-box .content-inner .imgcard-wrapper .img-card {
  width: 16.666%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.content-box .content-inner .imgcard-wrapper .img-card .dark-blue {
  background: url("../public/images/dark-blue-card.png") no-repeat;
  width: 100%;
  height: 100%;
  min-height: 260px;
  background-size: 100% 100%;
  padding: 5px;
  display: flex;
  word-break: break-all;
}
.content-box .content-inner .imgcard-wrapper .img-card .light-blue {
  background: url("../public/images/blue-card.png") no-repeat;
  width: 100%;
  height: 100%;
  min-height: 260px;
  background-size: 100% 100%;
  padding: 5px;
  display: flex;
  word-break: break-all;
}
.content-box .content-inner .imgcard-wrapper .img-card img {
  max-width: 100%;
  transition: 0.3s;
  cursor: pointer;
}
.content-box .content-inner .imgcard-wrapper .img-card:hover img {
  transform: scale(1.05);
}
.content-box .content-inner .textarea-content {
  padding-top: 0%;
}
.content-box .step-box {
  position: absolute;
  z-index: 100;
  top: 6%;
  left: 8%;
  z-index: 100 !important;
}
.content-box .close-btn {
  position: absolute;
  width: 40px;
  z-index: 100;
  right: 7%;
  top: 5%;
  z-index: 100 !important;
  transition: 250ms;
}
.content-box .close-btn:hover, .content-box .close-btn:active, .content-box .close-btn:focus {
  transform: scale(1.25);
}

.popup-match {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 90;
  display: none;
}
.popup-match.active {
  display: block;
}
.popup-match .pop-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.popup-match .pop-wrapper .popup-inner {
  position: relative;
  width: 100%;
  max-width: 1280px;
}

.test-modal {
  z-index: 100 !important;
}

.correction-btn button {
  max-width: 200px !important;
  max-height: 40px;
  transition: 250ms;
}
.correction-btn button img {
  width: 100%;
  height: 100%;
}
.correction-btn button:hover img {
  transform: scale(1.05);
}

.upload-box {
  position: absolute;
  left: 50%;
  top: 40%;
  padding: 0;
  transform: translate(-50%, -50%);
}

.in-f-box {
  display: block;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 50%;
  position: relative;
  text-align: center;
  /* Transition Background */
  transition: background 0.4s ease;
}
.in-f-box img {
  width: 100%;
  height: 140px;
  max-width: 140px;
}

.in-f-box input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.text-box {
  width: 100%;
  height: 100%;
  background: #4350c8;
  border-radius: 20px;
  padding: 10px 10px 0px 10px;
}
.text-box textarea {
  border-radius: 20px;
  border: 4px solid #fff;
  background: transparent;
  width: 100% !important;
  height: 100%;
  z-index: 1000;
  padding: 10px;
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  resize: none;
  outline: none;
  height: 100%;
}
.text-box textarea {
  overflow-y: scroll;
  margin: 0;
  padding: 10px;
  line-height: 34px;
  max-height: 340px;
}
.text-box textarea::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}
.text-box textarea::-webkit-scrollbar {
  width: 16px;
}

.msg-bg {
  width: 720px;
  position: absolute;
  left: 50%;
  padding: 0;
  bottom: -11%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1920px) {
  .content-box .modal-bg .training-bg {
    padding-top: 9%;
  }
  .content-box .close-btn {
    top: 17%;
    right: 1%;
    width: 80px;
  }
  .content-box .button-image {
    width: 280px;
    margin-top: 6%;
  }
  .content-box .step-box {
    position: absolute;
    z-index: 100;
    top: 18%;
    left: 4%;
    z-index: 100 !important;
  }
  .content-box textarea {
    max-height: 380px;
  }
  .content-box .msg-bg {
    width: 820px;
    position: absolute;
    left: 50%;
    padding: 0;
    bottom: 4%;
    transform: translate(-50%, -50%);
  }
  .modal-permission-bg {
    height: 32vh !important;
  }
}
.model-training {
  border: 1px solid #e9ebf0;
  padding: 20px;
  background: #fafbfc;
}

.current {
  background: #548fd2 !important;
  border: 1px solid #548fd2 !important;
}
.current p {
  color: #ffffff !important;
}

.bg-cloud {
  background: url("../public/images/upgrade-bg.svg") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-size: cover;
}

.shadow-modal {
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
}

.bg-gradient {
  background: rgb(153, 57, 133);
  background: linear-gradient(0deg, rgb(153, 57, 133) 0%, rgb(30, 111, 166) 100%);
}

.template-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(174, 174, 175, 0.5);
  z-index: 1000;
  display: none;
  border-radius: 15px;
}
.template-popup.active {
  display: block;
}
.template-popup .pop-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.template-popup .pop-wrapper .popup-inner {
  position: relative;
  width: 100%;
}

/* Add this CSS to your stylesheet or a <style> tag in your component */
.mermaid {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  /* Hide vertical overflow */
  white-space: nowrap;
  /* Prevent wrapping */
}

@media screen and (max-width: 1366px) {
  .rate-box small {
    font-size: 28px;
  }
  .rate-box strong {
    font-size: 32px !important;
  }
  .assign-name {
    margin-top: 50px !important;
  }
}
.title-around-span {
  position: relative;
  text-align: center;
  color: #000;
}
.title-around-span:before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: #E4E4E4;
}
.title-around-span span {
  position: relative;
  z-index: 1;
  padding: 0 15px;
  background: #fff;
}

.animation-tab .nav-pills {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px 0.5px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
}
.animation-tab .nav-pills .nav-item {
  width: 33.333%;
  z-index: 1;
}
.animation-tab .nav-pills .nav-item .nav-link {
  width: 100%;
  height: 100%;
  color: #BCBCBC;
  background-color: transparent;
}
.animation-tab .nav-pills .nav-item .nav-link h5 {
  font-weight: 800;
  font-size: 20px;
}
.animation-tab .nav-pills .nav-item .nav-link p {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
}
.animation-tab .nav-pills .nav-item .nav-link:hover {
  color: #9A3984;
}
.animation-tab .nav-pills .nav-item .nav-link.show {
  color: #FFFFFF;
}
.animation-tab .nav-pills .selected {
  position: absolute;
  transition: 0.5s all;
  width: 33.33%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 40px;
  z-index: 0;
  background: rgb(9, 123, 169);
  background: linear-gradient(90deg, rgb(9, 123, 169) 0%, rgb(158, 55, 131) 100%);
}

.animation-tabcurrency .nav-pills {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px 0.5px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
}
.animation-tabcurrency .nav-pills .nav-itemcurrency {
  width: 50%;
  z-index: 1;
}
.animation-tabcurrency .nav-pills .nav-itemcurrency .nav-link {
  width: 100%;
  height: 100%;
  color: #BCBCBC;
  background-color: transparent;
}
.animation-tabcurrency .nav-pills .nav-itemcurrency .nav-link h5 {
  font-weight: 800;
  font-size: 20px;
}
.animation-tabcurrency .nav-pills .nav-itemcurrency .nav-link p {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
}
.animation-tabcurrency .nav-pills .nav-itemcurrency .nav-link:hover {
  color: #9A3984;
}
.animation-tabcurrency .nav-pills .nav-itemcurrency .nav-link.show {
  color: #FFFFFF;
}
.animation-tabcurrency .nav-pills .selectedcurrency {
  position: absolute;
  transition: 0.5s all;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 40px;
  z-index: 0;
  background: rgb(9, 123, 169);
  background: linear-gradient(90deg, rgb(9, 123, 169) 0%, rgb(158, 55, 131) 100%);
}

.language-javascript {
  display: flex;
  white-space: break-spaces;
}

.code-block {
  position: relative;
  background-color: #f5f5f5;
  /* Change to your desired background color */
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem 0;
}

.code-header {
  margin: 28px 0;
  background-color: #6c757d;
}

.language-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #6c757d;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.875rem;
}

.copy-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.copy-btn:hover {
  background-color: #565b5f;
}

.epr-category-nav {
  display: none !important;
}

.file-card {
  display: flex;
  align-items: center;
  border: 1px solid #E9EBF0;
  border-radius: 10px;
  padding: 8px;
  margin-right: 8px;
}

.loader_file {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tweet-img .card-border {
  padding: 4px;
  border-radius: 10px;
  border: 2px solid transparent;
}
.tweet-img .card-border .card-box {
  transition: transform 0.3s ease;
  /* Smooth transition for transform */
  border-radius: 10px;
  position: relative;
  width: 100%;
  min-height: 140px;
  max-height: 140px;
  overflow: hidden;
}
.tweet-img .card-border .card-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
  left: 0;
  display: block;
  position: absolute;
}
.tweet-img .card-border .card-box video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
  left: 0;
  display: block;
  position: absolute;
}
.tweet-img .card-border .card-box button {
  background: #fff;
  border: none;
  position: absolute;
  left: 60px;
  right: 60px;
  bottom: 41%;
  display: none;
}
.tweet-img .card-border .card-box button:hover {
  background: #FFFFFF;
  color: #1A8CD1;
}
.tweet-img .card-border:hover {
  background-image: linear-gradient(90deg, white, white), linear-gradient(90deg, #1F6FA6, #993985);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  transition: 200ms;
}
.tweet-img .card-border:hover button {
  display: block !important;
}

.feedback-aside {
  padding: 0 !important;
}
.feedback-aside .canvas-heading {
  display: flex;
  align-items: center;
  background: rgb(10, 122, 169);
  background: linear-gradient(90deg, rgb(10, 122, 169) 0%, rgb(151, 58, 134) 100%);
  padding: 20px 10px;
}
.feedback-aside .canvas-heading button {
  padding: 0 !important;
  margin: 0 !important;
  background: none;
  outline: none;
  border: none;
  top: 15px !important;
}
.feedback-aside .canvas-heading button svg {
  width: 32px !important;
  height: 32px !important;
  color: #fff;
}
.feedback-aside .canvas-heading h5 {
  font-size: 28px;
  padding-left: 40px;
  margin: 0;
  color: #fff;
}
.feedback-aside .canvas-heading h5 svg {
  display: none;
}

.shadow-btn {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.glowing-border {
  animation: pulse 2s infinite;
  box-shadow: 0 0 15px rgba(9, 123, 169, 0.5), 0 0 25px rgba(9, 123, 169, 0.3);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px rgba(9, 123, 169, 0.5);
  }
  50% {
    box-shadow: 0 0 15px rgba(9, 123, 169, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(9, 123, 169, 0.5);
  }
}
.modal-grd {
  background: rgb(223, 81, 227);
  background: linear-gradient(0deg, rgb(223, 81, 227) 0%, rgb(110, 114, 243) 100%);
}

.react-joyride__overlay {
  pointer-events: none !important;
}

.nav-toggle span {
  pointer-events: none !important;
}
.nav-toggle span .icon {
  pointer-events: none !important;
}/*# sourceMappingURL=index.css.map */