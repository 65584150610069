@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&family=Reem+Kufi:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Nunito", sans-serif;
}

body.busy-cursor {
  cursor: wait !important;
  div {
    pointer-events: none;
  }
}

.l {
  box-shadow: 0 0 0 1px red;
}

* {
  scrollbar-width: auto;
  // scrollbar-color: #C8A366;
}


/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 12px;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  // background: #c6c6c6;
  background: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

// /* CSS to hide scrollbar for the custom-textarea class */
// .custom-textarea::-webkit-scrollbar {
//   display: none; /* for Chrome, Safari, and Opera */
// }
// .custom-textarea {
//   -ms-overflow-style: none;  /* for Internet Explorer and Edge */
//   scrollbar-width: none;  /* for Firefox */
// }
.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.ff-reemkufi {
  font-family: "Reem Kufi", sans-serif;
}

header {
  transition: 500ms;
  width: calc(100% - 280px);
  margin-left: 280px;
  position: fixed !important;
  top: 0;
  right: 0;
  // display: flex;
  align-items: center;
  backdrop-filter: blur(6px);
  z-index: 20;
  padding: 15px 22px;
}

aside {
  transition: 500ms;
  width: 280px;
  height: calc(100vh);
  background: #fafbfc;
  border-right: 1px solid #e9ebf0;
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 50 !important;
  z-index: 29;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  &.scroll-disabled {
    pointer-events: none;
  }
  .dashboard-logo {
    transition: 500ms;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 37px 40px 37px;
    a {
      text-decoration: none;
      img {
        min-width: 80px;
      }
    }
    button {
      transition: 500ms;
      background: transparent;
      cursor: pointer;
      color: #8b8b8b;
      padding: 0;
      border: none;
      outline: none;
      line-height: 1;
      font-size: 30px;
      &:hover {
        color: #1a71a7;
      }
    }
  }
  strong {
    padding-left: 20px;
    font-weight: 600;
    font-size: 18px;
  }
  // =====new
  ul {
    list-style-type: none;
    padding: 0 20px;
    margin: 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .nav-link {
      text-decoration: none;
      height: 44px;
      padding-left: 15px;
      padding-right: 10px;
      margin-top: 10px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      transition: 300ms;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      // &.active,
      &:hover {
        background: #e9f2ff;
        color: #097ba9;
        sup {
          color: #9d3784;
        }
      }
      &.nav-toggle {
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border-left: 2px solid #000;
          border-bottom: 2px solid #000;
          right: 15px;
          top: calc(50% - 6px);
          transform: rotate(-135deg);
          transition: 300ms;
        }
        &:hover {
          &::before {
            border-left-color: #097ba9;
            border-bottom-color: #097ba9;
          }
        }
        &.active {
          color: #097ba9;
          background-color: #e9f2ff;
          &::before {
            top: calc(50% - 8px);
            transform: rotate(-45deg);
            border-left: 2px solid #097ba9;
            border-bottom: 2px solid #097ba9;
          }
        }
      }
      strong {
        padding-left: 12px;
        pointer-events: none;
      }
    }
    .navmore-link {
      position: relative;
      margin: 0 auto;
      border-radius: 5px;
      display: none;
      &.active {
        display: block;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;
        padding: 10px 14px;
        font-weight: 600;
        font-size: 16px;
        color: #2a2a2a;
        &:hover {
          color: #097ba9;
        }
      }
    }
  }
}

.wrapper-setting {
  transition: 500ms;
  min-height: 100vh;
  margin-left: calc(280px + 15px + 15px);
  padding-top: calc(25px);
  padding-right: calc(15px + 15px);
  position: relative;
  z-index: 18;
}

.wrapper {
  transition: 500ms;
  min-height: 100vh;
  margin-left: calc(280px + 15px);
  padding-top: calc(62px + 15px + 20px);
  padding-right: 15px;
  position: relative;
  z-index: 18;
}

// ========
.shrink-menu {
  aside {
    transition: 500ms;
    width: 110px;
    .dashboard-logo {
      transition: 500ms;
      flex-direction: column;
      button {
        transition: 500ms;
        margin-top: 10px;
      }
    }
    .invite-box {
      display: flex;
      justify-content: center;
      span {
        display: none;
      }
    }
    .aside-modelbtn {
      display: flex;
      justify-content: center;
      span,
      .icon {
        display: none;
      }
    }
    ul {
      .nav-link {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        strong {
          display: none;
        }
      }
      word-break: break-all;
    }
    .navmore-link {
      padding: 0px;
      a {
        padding: 10px 5px;
        font-size: 13px;
      }
    }
    .nav-toggle {
      &::before {
        content: "";
        display: none;
      }

    }
  }
  header {
    transition: 500ms;
    width: calc(100% - 110px);
    margin-left: calc(110px + 37px);
    padding-left: 37px;
  }
  .wrapper {
    transition: 500ms;
    margin-left: calc(110px + 37px);
  }
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid #ffffff;
  }
}

.text-btn {
  li {
    padding: 15px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }
  }
}

/////
.dropdown {
  &:hover {
    .dropdown-menu {
      display: block;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
}

.dropdown-aside {
  display: none;
  width: 180px;
  // min-height: 200px;
  height: auto;
  z-index: 50;
  position: fixed;
}

.tooltip {
  background: linear-gradient( 90deg, rgba(9, 123, 169, 1) 0%, rgba(155, 55, 133, 1) 100%);
  .rotate-45 {
    background: #097ba9;
  }
}

.table {
  width: 100%;
  // background: #FAFBFC;
  color: #949494;
  border-collapse: collapse;
  thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
    th {
      background: #fafbfc;
      &:first-child {
        border-radius: 25px 0 0 25px;
      }
      &:last-child {
        border-radius: 0 25px 25px 0;
      }
    }
  }
  thead,
  tbody {
    box-shadow: 0 0 0 1px #dcdcdc;
    border-radius: 25px;
  }
  tbody {
    &.tbody-gap {
      opacity: 0;
      line-height: 5px;
      td {
        opacity: 0;
        line-height: 5px;
        padding-bottom: 15px;
        border: 0;
      }
    }
    tr {
      &:first-child {
        td {
          &:first-child {
            border-radius: 25px 0 0 0px;
          }
          &:last-child {
            border-radius: 0 25px 0 0;
          }
        }
      }
      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 25px;
          }
          &:last-child {
            border-radius: 0 0 25px 0;
          }
        }
      }
      td {
        background: #fafbfc;
        border-bottom: 1px solid #dcdcdc;
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
    .group {
      border-radius: 25px !important;
      padding: 0 !important;
      background: #236da5 !important;
    }
  }
  tr {
    color: #949494;
    th {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      padding: 12px 0px;
      width: auto;
      min-width: 140px;
    }
    td {
      text-align: center;
      padding: 12px 0px;
    }
  }
}

.modal-dropdown {
  .group {
    border-radius: 25px !important;
    padding: 0 !important;
    border: none !important;
    background: linear-gradient( 90deg, rgba(9, 123, 169, 1) 0%, rgba(155, 55, 133, 1) 100%) !important;
    svg {
      display: none !important;
    }
  }
}

.tabs-box {
  .space-x-2 {
    button {
      border-radius: 25px !important;
      padding: 8px 12px;
      border: 1px solid #0891b2;
      margin-right: 20px;
    }
  }
}

.modal-aside {
  position: relative;
  .aside {
    width: 240px;
    border-right: 1px solid #e0e0e0;
    z-index: 21;
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow-y: auto;
    ul {
      list-style-type: none;
      margin: 0;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 10px;
      .nav-link {
        text-decoration: none;
        height: 38px;
        padding-left: 15px;
        padding-right: 10px;
        // margin-top: 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        transition: 300ms;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        // &.active,
        &:hover {
          background: #e9f2ff;
          color: #097ba9;
          sup {
            color: #9d3784;
          }
        }
        &.nav-toggle {
          &::before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            border-left: 2px solid #000;
            border-bottom: 2px solid #000;
            right: 15px;
            top: calc(50% - 6px);
            transform: rotate(-135deg);
            transition: 300ms;
          }
          &:hover {
            &::before {
              border-left-color: #097ba9;
              border-bottom-color: #097ba9;
            }
          }
          &.active {
            color: #097ba9;
            background-color: #e9f2ff;
            &::before {
              top: calc(50% - 8px);
              transform: rotate(-45deg);
              border-left: 2px solid #097ba9;
              border-bottom: 2px solid #097ba9;
            }
          }
        }
        strong {
          padding-left: 12px;
          pointer-events: none;
          font-weight: 500;
          font-size: 18px;
        }
      }
      .navmore-link {
        position: relative;
        margin: 0 auto;
        border-radius: 5px;
        display: none;
        &.active {
          display: block;
        }
        a {
          display: flex;
          align-items: center;
          width: 100%;
          text-decoration: none;
          padding: 10px 14px;
          font-weight: 500;
          font-size: 18px;
          color: #2a2a2a;
          &:hover {
            color: #097ba9;
          }
        }
      }
    }
  }
}

.ck.ck-powered-by {
  display: none !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 50px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #333;
  /* You can set the color you prefer */
  border-radius: 50%;
  margin: 0 5px;
  animation: pulse 1.2s infinite;
}

.dot1 {
  animation-delay: 0.2s;
  background-color: #2ecc71;
}

.dot2 {
  animation-delay: 0.4s;
  background-color: #27ae60;
}

.dot3 {
  animation-delay: 0.6s;
  background-color: #229954;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

.tox-statusbar__branding {
  display: none;
}

.i-tooltip {
  width: 267px;
  height: 208px;
  top: 632px;
  left: 672px;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 4px 12px 0px #00000040;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.code-view {
  padding: 1rem;
  margin: 10px;
}

.text-area {
  width: 100%;
  background: black;
  border: 1px solid #ccc;
  color: white;
  min-height: 200px;
}

// label::after {
//   content: " *";
// }
.modal-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000 !important;
  background: rgba(0, 0, 0, 0.25);
  // background: rgba(24, 129, 225, 0.4);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  transition: 350ms;
  &.show {
    visibility: visible;
    opacity: 1;
  }
  .popup-body {
    width: 100%;
    max-width: 1000px;
    height: auto;
    background: #ffffff;
    box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000 !important;
    padding: 20px 30px;
    background: #ffff;
    .pop-btn {
      outline: none;
      border: none;
      color: #949494;
      background: transparent;
      cursor: pointer;
    }
  }
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

// new chat css
.chat-field {
  textarea {
    padding: 10px 40px 10px 10px;
    border: 1px solid white;
    outline: none;
    resize: none;
    transition: 0.25s;
    &:focus {
      border: 1px solid rgb(56, 140, 197);
      box-shadow: 0 0 0 0.2rem rgba(59, 133, 182, 0.25);
    }
  }
}

.chat-field {
  .sendbtn {
    background-image: linear-gradient( 43deg, #1e6fa6 0%, #9d3784 46%, #9d3784 100%);
  }
}

.chat-field {
  .sendbtn:hover {
    color: #ffffff;
    transform: scale(1.35);
    outline: none;
  }
}

//
.title {
  align-items: center;
  white-space: nowrap;
}

.title--center-line {
  text-align: center;
  &::before {
    content: "";
    background: #f1f1f1;
    height: 1px;
    width: 100%;
    margin-right: 1rem;
  }
  &::after {
    content: "";
    background: #f1f1f1;
    height: 1px;
    width: 100%;
    margin-left: 1rem;
  }
}

// Upload Demo//
.uploader {
  display: block;
  width: 80px;
  height: 80px;
  label {
    float: left;
    clear: both;
    width: 100%;
    text-align: center;
    background: #f2f7ff;
    border-radius: 50%;
    transition: all 0.2s ease;
    user-select: none;
    width: 80px;
    height: 80px;
  }
  #start {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
    i.fa {
      font-size: 50px;
      margin-bottom: 1rem;
      transition: all 0.2s ease-in-out;
    }
  }
  #response {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
    #messages {
      margin-bottom: 0.5rem;
    }
  }
  #file-image {
    display: inline;
    // margin: 0 auto .5rem auto;
    width: auto;
    height: auto;
    max-width: 80px;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    &.hidden {
      display: none;
    }
  }
  #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
  }
  input[type="file"] {
    display: none;
  }
}

.avtart-shadow {
  box-shadow: 0px 0px 8px 0px #63636324;
}

.avtar-boxes {
  div {
    width: 44px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    img {
      border-radius: 50%;
    }
    &:hover {
      border: 1px solid #5de56a;
    }
  }
}

.workspace-main {
  height: 100vh;
  overflow-y: hidden;
  .workspace-main__space {
    height: 180px;
  }
  .workspace-main__space_last {
    height: 180px;
  }
}

button:disabled {
  cursor: not-allowed;
}


/* styles.css */

.blinking-cursor {
  right: 5px;
  top: 5px;
  height: 1em;
  width: 0.5em;
  background-color: rgb(56, 140, 197);
  animation: blinkCursor 1s infinite;
}

@keyframes blinkCursor {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

// .workspace_nav {
//   position: sticky;
//   left: 0;
//   top: 0;
//   background: white;
//   z-index: 1;
// }
.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient( to right, transparent, rgba(219, 219, 219, 0.5), transparent);
  animation: loading 1.5s infinite;
}

.skeleton>* {
  visibility: hidden;
}

@keyframes loading {
  to {
    left: 100%;
  }
}

.text-editor {
  a {
    color: #0d6efd;
    text-decoration: underline;
  }
  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 2rem;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
  ol {
    padding-left: 2rem;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
  p {
    padding: 0.8em 0;
  }
}

// .chat-line{
//   position: relative;
//   .btn-plus{
//     position: absolute;
//     right: -18px;
//     top: 10px;
//   }
// }
.icon_plus {
  // width: 20%;
}

.card-boxes {
  .card {
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 4px 10px 0px #00000026;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .lable-box {
      color: #cf4dcb;
      font-size: 10px;
      font-weight: 600;
      background: #ffe5fe;
      padding: 8px 20px;
      border-radius: 25px;
    }
    .card-body {
      padding: 20px 0px 10px 0px;
      .check-card {
        // color: #704C8F;
        // color: #953C86;
        font-size: 14px;
      }
      h5 {
        font-size: 18px;
        font-weight: 700;
        background: linear-gradient( 90deg, #097ba9 0%, #9f3783 50%, #9f3783 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .ctn-btn {
        text-decoration: none;
        display: block;
        text-align: center;
      }
      ul {
        list-style-type: none;
        margin: 20px 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          span {
            font-size: 24px;
            margin-right: 10px;
          }
        }
      }
      .rate-box {
        display: flex;
        align-items: center;
        justify-content: center;
        small {
          font-family: Arial;
          padding: 0 5px;
          font-size: 34px;
          line-height: 1;
          font-weight: 700;
          background: linear-gradient( 90deg, #097ba9 0%, #9f3783 50%, #9f3783 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        strong {
          font-size: 50px;
          font-weight: 700;
          background: linear-gradient( 90deg, #097ba9 0%, #9f3783 50%, #9f3783 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        sub {
          font-size: 14px;
          font-weight: 400;
          // color: #2A2A2A;
          // -webkit-text-fill-color: #2A2A2A;
        }
      }
      .badge-box {
        display: inline;
        background: #efefef;
        border-radius: 8px;
        padding: 8px 16px;
        font-size: 12px;
        font-weight: 500;
        strong {
          font-weight: 800;
        }
      }
    }
    transition: transform 0.9s;
    &:hover {
      background: rgb(68, 97, 155);
      // background: linear-gradient(0deg, rgba(68, 97, 155, 1) 0%, rgba(149, 60, 134, 1) 100%);
      background: linear-gradient( 0deg, rgba(149, 60, 134, 1) 0%, rgba(68, 97, 155, 1) 100%);
      color: #ffffff;
      transform: scale(1.06);
      .rate-box {
        small {
          filter: brightness(0) invert(1);
        }
      }
      .check-card {
        // color: #FFFFFF !important;
        filter: brightness(0) invert(1);
      }
      h5 {
        background-clip: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: none !important;
        color: #ffffff !important;
      }
      .btn-default {
        color: #704c8f;
      }
      .lable-box {
        background: #ffffff;
      }
    }
    .card-footer {
      margin-top: auto;
      padding: 20px;
    }
  }
  .card-selected {
    background: rgb(68, 97, 155);
    // background: linear-gradient(0deg, rgba(68, 97, 155, 1) 0%, rgba(149, 60, 134, 1) 100%);
    background: linear-gradient( 0deg, rgba(149, 60, 134, 1) 0%, rgba(68, 97, 155, 1) 100%);
    color: #ffffff;
    transform: scale(1.06);
    .rate-box {
      small {
        filter: brightness(0) invert(1);
      }
    }
    .check-card {
      // color: #FFFFFF !important;
      filter: brightness(0) invert(1);
    }
    h5 {
      background-clip: none;
      -webkit-background-clip: none;
      -webkit-text-fill-color: none !important;
      color: #ffffff !important;
    }
    .btn-default {
      color: #704c8f;
    }
    .lable-box {
      background: #ffffff;
    }
  }
}

.btn-default-cancel {
  background: transparent;
  padding: 0.5rem 1rem;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid black;
  border-radius: 25px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.4;
  // &:hover{
  //   transform: scale(1.06);
  // }
}

.btn-default {
  background: transparent;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(90deg, white, white), linear-gradient(90deg, #097ba9, #9c3784);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid transparent;
  border-radius: 25px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  // &:hover {
  //   // background: #6263AD;
  //   // background: #953C86;
  //   background: #F682E3;
  //   border: 1px solid #F682E3;
  //   color: #FFFFFF !important;
  // }
}

// ==================otp
.otp-input {
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 68%;
    height: 64px;
    margin: 5px 14px;
    border-radius: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #0c0d25;
    text-align: center;
  }
  input {
    // border: 2px solid #2ba17c;
    // background: #e6faf3;
    background: #eac5df;
    border: none;
    box-shadow: 0px 0px 8px 0px #00000040;
  }
  input:invalid {
    // border: 2px solid #0c0d25 !important;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px #00000040;
  }
}

// game css
.main-body {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.main-wrapper {
  height: 100vh;
  position: relative;
  background: url("../public/images/common-bg.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  .button-image {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    transition: 250ms;
    z-index: 100;
    width: 220px;
    img {
      max-width: 100%;
      display: block;
    }
    &:hover,
    &:active,
    &:focus {
      transform: scale(1.03);
    }
  }
  .cloud-box {
    position: absolute;
    overflow: hidden;
    width: 100%;
    #clouds {
      padding: 10px 0;
    }
    .cloud {
      width: 200px;
      height: 80px;
      img {
        height: 100%;
      }
    }
    .x1 {
      animation: moveclouds 20s linear infinite;
    }
    .x2 {
      left: 200px;
      transform: scale(0.6);
      animation: moveclouds 25s linear infinite;
    }
    .x3 {
      left: -250px;
      top: -200px;
      transform: scale(0.8);
      animation: moveclouds 30s linear infinite;
    }
    @keyframes moveclouds {
      0% {
        margin-left: 100vw;
      }
      100% {
        margin-left: -100vw;
      }
    }
  }
}


/*Start | Progressbar style*/

.progressing-bar {
  left: 0;
  // top: 45%;
  margin: 0px auto;
  position: relative;
  width: 100%;
  max-width: 820px;
  height: 88px;
  background-color: transparent;
  border-radius: 22px;
  border: 6px solid #ffffff;
  padding: 4px;
  text-align: center;
  .progressing-in {
    border: 6px solid #fff;
    border-radius: 14px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #097ba9, #9f3782);
    position: relative;
    transition: 300ms;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient( -45deg, rgba(255, 255, 255, 1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 75%, transparent 75%, transparent);
      background-size: 38px 38px;
      animation: animate-stripes 20s linear infinite;
    }
  }
  h2 {
    margin: 0;
    font-size: 24px;
    color: #fff;
  }
  input[type="range"] {
    width: 100%;
    max-width: 280px;
    margin-top: 50px;
  }
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1000px 0;
  }
}


/*End | Progressbar style*/

// spinner
.animation {
  // margin-top: 10%;
  display: inline-block;
  margin-bottom: 2%;
}

.test,
.one,
.two,
.three {
  display: block;
  float: left;
}

.one {
  background: url("../public/images/setting.svg");
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 40px;
  margin-right: 6px;
}

.test {
  background: url("../public/images/setting.svg");
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 65px;
  margin-right: -46px;
}


/* pap */

.two {
  background: url("../public/images/setting.svg");
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}


/* pap */

.three {
  background: url("../public/images/setting.svg");
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 40px;
  margin-left: 6px;
}


/* pap */

.four {
  background: url("../public/images/setting.svg");
  width: 54px;
  height: 54px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 65px;
  margin-left: 130px;
}

.five {
  background: url("../public/images/setting.svg");
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: -20px;
  margin-left: 100px;
}

@keyframes spin-test {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.spin-test {
  -webkit-animation: spin-test 1.5s infinite linear;
  animation: spin-test 1.5s infinite linear;
}

@keyframes spin-one {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.spin-one {
  -webkit-animation: spin-one 1.5s infinite linear;
  animation: spin-one 1.5s infinite linear;
}

@keyframes spin-two {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(359deg);
  }
}

.spin-two {
  -webkit-animation: spin-two 2s infinite linear;
  animation: spin-two 2s infinite linear;
}

.content-box {
  // position: absolute;
  position: relative;
  margin: 0px auto;
  width: 100%;
  height: auto;
  max-width: 1280px;
  .modal-bg {
    background: url("../public/images/bg-modal.svg") no-repeat;
    width: 100%;
    background-size: 100% 100%;
    height: 92vh;
    position: relative;
  }
  .modal-permission-bg {
    background: url("../public/images/bg-modal.svg") no-repeat;
    width: 100%;
    max-width: 900px;
    background-size: 100% 100%;
    height: 42vh;
    position: relative;
    margin: 0px auto;
  }
  .content-inner {
    width: 80%;
    display: flex;
    gap: 0 4%;
    justify-content: center;
    .content-col {
      width: calc(100% - 2%);
    }
    .imgcard-wrapper {
      padding-top: 10%;
      display: flex;
      flex-wrap: wrap;
      .img-card {
        width: 16.666%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 5px;
        .dark-blue {
          background: url("../public/images/dark-blue-card.png") no-repeat;
          width: 100%;
          height: 100%;
          min-height: 260px;
          background-size: 100% 100%;
          padding: 5px;
          display: flex;
          word-break: break-all;
        }
        .light-blue {
          background: url("../public/images/blue-card.png") no-repeat;
          width: 100%;
          height: 100%;
          min-height: 260px;
          background-size: 100% 100%;
          padding: 5px;
          display: flex;
          word-break: break-all;
        }
        img {
          max-width: 100%;
          transition: 0.3s;
          cursor: pointer;
        }
        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
    .textarea-content {
      padding-top: 0%;
    }
  }
  .step-box {
    position: absolute;
    z-index: 100;
    top: 6%;
    left: 8%;
    z-index: 100 !important;
  }
  .close-btn {
    position: absolute;
    width: 40px;
    z-index: 100;
    right: 7%;
    top: 5%;
    z-index: 100 !important;
    transition: 250ms;
    &:hover,
    &:active,
    &:focus {
      transform: scale(1.25);
    }
  }
}

// pop match
.popup-match {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 90;
  display: none;
  &.active {
    display: block;
  }
  .pop-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .popup-inner {
      position: relative;
      width: 100%;
      max-width: 1280px;
    }
  }
}

.test-modal {
  z-index: 100 !important;
}

.correction-btn {
  button {
    max-width: 200px !important;
    max-height: 40px;
    transition: 250ms;
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}

// new css 06/05/24
.upload-box {
  position: absolute;
  left: 50%;
  top: 40%;
  padding: 0;
  transform: translate(-50%, -50%);
}

.in-f-box {
  display: block;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 50%;
  position: relative;
  text-align: center;
  /* Transition Background */
  -webkit-transition: background 0.4s ease;
  -moz-transition: background 0.4s ease;
  -o-transition: background 0.4s ease;
  -ms-transition: background 0.4s ease;
  transition: background 0.4s ease;
  img {
    width: 100%;
    height: 140px;
    max-width: 140px;
  }
}

.in-f-box input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

// text box
.text-box {
  width: 100%;
  height: 100%;
  background: #4350c8;
  border-radius: 20px;
  padding: 10px 10px 0px 10px;
  textarea {
    border-radius: 20px;
    border: 4px solid #fff;
    background: transparent;
    width: 100% !important;
    height: 100%;
    z-index: 1000;
    padding: 10px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    resize: none;
    outline: none;
    height: 100%;
    & {
      overflow-y: scroll;
      margin: 0;
      padding: 10px;
      line-height: 34px;
      max-height: 340px;
    }
  }
  textarea::-webkit-scrollbar-thumb {
    background-color: #fff;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }
  textarea::-webkit-scrollbar {
    width: 16px;
  }
}

.msg-bg {
  width: 720px;
  position: absolute;
  left: 50%;
  padding: 0;
  bottom: -11%;
  transform: translate(-50%, -50%);
}

// media query
@media only screen and (min-width: 1920px) {
  .content-box {
    .modal-bg {
      .training-bg {
        padding-top: 9%;
      }
    }
    .close-btn {
      top: 17%;
      right: 1%;
      width: 80px;
    }
    .button-image {
      width: 280px;
      margin-top: 6%;
    }
    .step-box {
      position: absolute;
      z-index: 100;
      top: 18%;
      left: 4%;
      z-index: 100 !important;
    }
    textarea {
      & {
        max-height: 380px;
      }
    }
    .msg-bg {
      width: 820px;
      position: absolute;
      left: 50%;
      padding: 0;
      bottom: 4%;
      transform: translate(-50%, -50%);
    }
  }
  .modal-permission-bg {
    height: 32vh !important;
  }
}

// model training page
.model-training {
  border: 1px solid #e9ebf0;
  padding: 20px;
  background: #fafbfc;
}

.current {
  background: #548fd2 !important;
  border: 1px solid #548fd2 !important;
  p {
    color: #ffffff !important;
  }
}

// popup
.bg-cloud {
  background: url("../public/images/upgrade-bg.svg") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-size: cover;
}

.shadow-modal {
  box-shadow: 0px 4px 15px 0px #00000026;
}

.bg-gradient {
  background: rgb(153, 57, 133);
  background: linear-gradient( 0deg, rgba(153, 57, 133, 1) 0%, rgba(30, 111, 166, 1) 100%);
}

.template-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba($color: #aeaeaf, $alpha: 0.5);
  z-index: 1000;
  display: none;
  border-radius: 15px;
  &.active {
    display: block;
  }
  .pop-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .popup-inner {
      position: relative;
      width: 100%;
    }
  }
}


/* Add this CSS to your stylesheet or a <style> tag in your component */

// .image-container {
//   position: relative;
//   display: inline-block;
//   margin: 0 auto;
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .image-container img {
//   display: block;
//   margin: 0 auto;
// }
// .image-container .hover-buttons {
//   display: none;
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
// }
// .image-container .hover-buttons .top-left,
// .image-container .hover-buttons .bottom-right,
// .image-container .hover-buttons .close-btn {
//   position: absolute;
//   cursor: pointer;
//   background: rgba(0, 0, 0, 0.5);
//   color: white;
//   padding: 5px;
//   border-radius: 50%;
// }
// .image-container .hover-buttons .top-left {
//   top: 5px;
//   left: 5px;
// }
// .image-container .hover-buttons .bottom-right {
//   bottom: 5px;
//   right: 5px;
// }
// .image-container .hover-buttons .close-btn {
//   top: 5px;
//   right: 5px;
// }
// .image-container:hover .hover-buttons {
//   display: block;
// }
.mermaid {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  /* Hide vertical overflow */
  white-space: nowrap;
  /* Prevent wrapping */
}

@media screen and (max-width: 1366px) {
  .rate-box {
    small {
      font-size: 28px;
    }
    strong {
      font-size: 32px !important;
    }
  }
  .assign-name {
    margin-top: 50px !important;
  }
}

.title-around-span {
  position: relative;
  text-align: center;
  color: #000;
  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: #E4E4E4;
  }
  span {
    position: relative;
    z-index: 1;
    padding: 0 15px;
    background: #fff;
  }
}

// new tab 30/05/2024
.animation-tab {
  .nav-pills {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px 0.5px #00000033;
    border-radius: 40px;
    .nav-item {
      width: 33.333%;
      z-index: 1;
      .nav-link {
        width: 100%;
        height: 100%;
        color: #BCBCBC;
        background-color: transparent;
        h5 {
          font-weight: 800;
          font-size: 20px;
        }
        p {
          font-family: Nunito;
          font-size: 11px;
          font-weight: 600;
        }
        &:hover {
          color: #9A3984;
        }
        &.show {
          color: #FFFFFF;
        }
      }
    }
    .selected {
      position: absolute;
      transition: 0.5s all;
      width: 33.33%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 40px;
      z-index: 0;
      background: rgb(9, 123, 169);
      background: linear-gradient(90deg, rgba(9, 123, 169, 1) 0%, rgba(158, 55, 131, 1) 100%);
    }
  }
}

// currency
.animation-tabcurrency {
  .nav-pills {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px 0.5px #00000033;
    border-radius: 40px;
    .nav-itemcurrency {
      width: 50%;
      z-index: 1;
      .nav-link {
        width: 100%;
        height: 100%;
        color: #BCBCBC;
        background-color: transparent;
        h5 {
          font-weight: 800;
          font-size: 20px;
        }
        p {
          font-family: Nunito;
          font-size: 11px;
          font-weight: 600;
        }
        &:hover {
          color: #9A3984;
        }
        &.show {
          color: #FFFFFF;
        }
      }
    }
    .selectedcurrency {
      position: absolute;
      transition: 0.5s all;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 40px;
      z-index: 0;
      background: rgb(9, 123, 169);
      background: linear-gradient(90deg, rgba(9, 123, 169, 1) 0%, rgba(158, 55, 131, 1) 100%);
    }
  }
}

.language-javascript {
  display: flex;
  white-space: break-spaces;
}

.code-block {
  position: relative;
  background-color: #f5f5f5;
  /* Change to your desired background color */
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem 0;
}

.code-header {
  margin: 28px 0;
  background-color: #6c757d;
}

.language-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #6c757d;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.875rem;
}

.copy-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.copy-btn:hover {
  background-color: #565b5f;
}

.epr-category-nav {
  display: none !important;
}

.file-card {
  display: flex;
  align-items: center;
  border: 1px solid #E9EBF0;
  border-radius: 10px;
  padding: 8px;
  margin-right: 8px;
}

.loader_file {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// new css
.tweet-img {
  .card-border {
    padding: 4px;
    border-radius: 10px;
    border: 2px solid transparent;
    .card-box {
      // background: #00000080;
      transition: transform 0.3s ease;
      /* Smooth transition for transform */
      border-radius: 10px;
      position: relative;
      // min-width: 265px;
      width: 100%;
      min-height: 140px;
      max-height: 140px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        display: block;
        position: absolute;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        display: block;
        position: absolute;
      }
      button {
        background: #fff;
        border: none;
        position: absolute;
        left: 60px;
        right: 60px;
        bottom: 41%;
        display: none;
        &:hover {
          background: #FFFFFF;
          color: #1A8CD1;
        }
      }
    }
    &:hover {
      background-image: linear-gradient(90deg, white, white), linear-gradient(90deg, #1F6FA6, #993985);
      background-clip: padding-box, border-box;
      background-origin: border-box;
      // border: 2px solid transparent;
      // border-radius: 10px;
      button {
        display: block !important;
      }
      transition: 200ms;
    }
  }
}

// feedback
.feedback-aside {
  padding: 0 !important;
  .canvas-heading {
    display: flex;
    align-items: center;
    background: rgb(10, 122, 169);
    background: linear-gradient(90deg, rgba(10, 122, 169, 1) 0%, rgba(151, 58, 134, 1) 100%);
    padding: 20px 10px;
    button {
      padding: 0 !important;
      margin: 0 !important;
      background: none;
      outline: none;
      border: none;
      top: 15px !important;
      svg {
        width: 32px !important;
        height: 32px !important;
        color: #fff;
      }
    }
    h5 {
      font-size: 28px;
      padding-left: 40px;
      margin: 0;
      color: #fff;
      svg {
        display: none;
      }
    }
  }
}

.shadow-btn {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.glowing-border {
  // border: 2px solid #097BA9;
  // border: 2px solid #FAFBFC;
  animation: pulse 2s infinite;
  box-shadow: 0 0 15px rgba(9, 123, 169, 0.5), 0 0 25px rgba(9, 123, 169, 0.3);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px rgba(9, 123, 169, 0.5);
  }
  50% {
    box-shadow: 0 0 15px rgba(9, 123, 169, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(9, 123, 169, 0.5);
  }
}
// 
.modal-grd {
  background: rgb(223, 81, 227);
  background: linear-gradient(0deg, rgba(223, 81, 227, 1) 0%, rgba(110, 114, 243, 1) 100%);
}
.react-joyride__overlay{
  pointer-events: none !important;
}
.nav-toggle{
  span{
    pointer-events: none !important;
    .icon{
      pointer-events: none !important;
    }
  }
}